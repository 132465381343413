define('ember-gestures/recognizers/pinch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    include: [],
    exclude: [],
    options: {},
    recognizer: 'pinch'
  };
});