define("ember-light-table/templates/components/light-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "507/IGch",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"head\",\"body\",\"foot\"],[[27,\"component\",[\"lt-head\"],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[23,[\"elementId\"]],[23,[\"table\"]],[23,[\"tableActions\"]],[23,[\"extra\"]],[23,[\"tableClassNames\"]],[23,[\"sharedOptions\"]]]]],[27,\"component\",[\"lt-body\"],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[23,[\"elementId\"]],[23,[\"table\"]],[23,[\"tableActions\"]],[23,[\"extra\"]],[23,[\"tableClassNames\"]],[23,[\"sharedOptions\"]]]]],[27,\"component\",[\"lt-foot\"],[[\"tableId\",\"table\",\"tableActions\",\"extra\",\"tableClassNames\",\"sharedOptions\"],[[23,[\"elementId\"]],[23,[\"table\"]],[23,[\"tableActions\"]],[23,[\"extra\"]],[23,[\"tableClassNames\"]],[23,[\"sharedOptions\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/light-table.hbs"
    }
  });

  _exports.default = _default;
});