define("ember-composable-helpers/-private/create-needle-haystack-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createNeedleHaystackHelper;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var K = function K() {};
  /**
   * Creates a generic Helper class implementation that expects a `needle` and
   * `haystack` as arguments. A `fn` function is required to be passed in
   * that is invoked with the `needle` and `haystack` arguments.
   *
   * @private
   * @param  {Function} fn A function to run against the needle and haystack
   * @return {Any}
   */


  function createNeedleHaystackHelper() {
    var fn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : K;
    return Ember.Helper.extend({
      content: Ember.computed('needle.[]', 'haystack.[]', 'option', function () {
        var needle = Ember.get(this, 'needle');
        var haystack = Ember.get(this, 'haystack');
        var option = Ember.get(this, 'option');
        return fn(needle, haystack, option);
      }).readOnly(),
      compute: function compute(_ref) {
        var _ref2 = _slicedToArray(_ref, 3),
            needle = _ref2[0],
            option = _ref2[1],
            haystack = _ref2[2];

        if (Ember.isEmpty(haystack)) {
          haystack = option;
          option = null;
        }

        Ember.set(this, 'needle', needle);
        Ember.set(this, 'haystack', haystack);
        Ember.set(this, 'option', option);
        return Ember.get(this, 'content');
      },
      contentDidChange: Ember.observer('content', function () {
        this.recompute();
      })
    });
  }
});