define("ember-composable-helpers/helpers/append", ["exports", "ember-composable-helpers/-private/create-multi-array-helper"], function (_exports, _createMultiArrayHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.append = append;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function append() {
    for (var _len = arguments.length, dependentKeys = new Array(_len), _key = 0; _key < _len; _key++) {
      dependentKeys[_key] = arguments[_key];
    }

    dependentKeys = dependentKeys || [];
    var arrayKeys = dependentKeys.map(function (dependentKey) {
      return "".concat(dependentKey, ".[]");
    });
    return Ember.computed.apply(void 0, _toConsumableArray(arrayKeys).concat([function () {
      var _this = this,
          _ref;

      var array = dependentKeys.map(function (dependentKey) {
        var value = Ember.get(_this, dependentKey);
        return Ember.isArray(value) ? value.toArray() : [value];
      });
      return (_ref = []).concat.apply(_ref, _toConsumableArray(array));
    }]));
  }

  var _default = (0, _createMultiArrayHelper.default)(append);

  _exports.default = _default;
});