define("ember-composable-helpers/-private/create-multi-array-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var idForArray = function idForArray(array) {
    return "__array-".concat(Ember.guidFor(array));
  };

  function _default(multiArrayComputed) {
    return Ember.Helper.extend({
      compute: function compute(_ref) {
        var _ref2 = _toArray(_ref),
            arrays = _ref2.slice(0);

        Ember.set(this, 'arrays', arrays.map(function (obj) {
          if (Ember.isArray(obj)) {
            return Ember.A(obj);
          }

          return obj;
        }));
        return Ember.get(this, 'content');
      },
      valuesDidChange: Ember.observer('arrays.[]', function () {
        this._recomputeArrayKeys();

        var arrays = Ember.get(this, 'arrays');
        var arrayKeys = Ember.get(this, 'arrayKeys');

        if (Ember.isEmpty(arrays)) {
          Ember.defineProperty(this, 'content', []);
          return;
        }

        Ember.defineProperty(this, 'content', multiArrayComputed.apply(void 0, _toConsumableArray(arrayKeys)));
      }),
      contentDidChange: Ember.observer('content.[]', function () {
        this.recompute();
      }),
      _recomputeArrayKeys: function _recomputeArrayKeys() {
        var _this = this;

        var arrays = Ember.get(this, 'arrays');
        var oldArrayKeys = Ember.get(this, 'arrayKeys') || [];
        var newArrayKeys = arrays.map(idForArray);
        var keysToRemove = oldArrayKeys.filter(function (key) {
          return newArrayKeys.indexOf(key) === -1;
        });
        keysToRemove.forEach(function (key) {
          return Ember.set(_this, key, null);
        });
        arrays.forEach(function (array) {
          return Ember.set(_this, idForArray(array), array);
        });
        Ember.set(this, 'arrayKeys', newArrayKeys);
      }
    });
  }
});