define("ember-toggle/components/x-toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "niXcqGW/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[11,\"class\",\"x-toggle\"],[12,\"checked\",[21,\"toggled\"]],[12,\"disabled\",[21,\"disabled\"]],[12,\"id\",[21,\"forId\"]],[12,\"name\",[21,\"name\"]],[12,\"onchange\",[27,\"action\",[[22,0,[]],[23,[\"sendToggle\"]]],[[\"value\"],[\"target.checked\"]]]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n\\n\"],[7,\"label\"],[12,\"for\",[21,\"effectiveForId\"]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"id\",[28,[\"x-toggle-visual-\",[21,\"forId\"]]]],[11,\"role\",\"checkbox\"],[12,\"class\",[28,[\"x-toggle-btn \",[21,\"themeClass\"],\" \",[21,\"size\"],[27,\"if\",[[23,[\"disabled\"]],\" x-toggle-disabled\"],null]]]],[12,\"aria-owns\",[21,\"forId\"]],[12,\"aria-checked\",[21,\"toggled\"]],[12,\"data-tg-on\",[21,\"onLabel\"]],[12,\"data-tg-off\",[21,\"offLabel\"]],[9],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-toggle/components/x-toggle-switch/template.hbs"
    }
  });

  _exports.default = _default;
});