define("ember-gestures/utils/string/cap-first-letter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
});