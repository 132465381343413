define("ember-scrollable/util/number", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    isNaN: Number.isNaN || window.isNaN,
    parseInt: Number.parseInt || window.parseInt
  };
});