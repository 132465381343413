define("ember-modal-dialog/initializers/add-modals-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /*globals document, Ember */
  var hasDOM = typeof document !== 'undefined';

  function appendContainerElement(rootElementOrId, id) {
    if (!hasDOM) {
      return;
    }

    if (document.getElementById(id)) {
      return;
    }

    var rootEl = rootElementOrId.appendChild ? rootElementOrId : document.querySelector(rootElementOrId);
    var modalContainerEl = document.createElement('div');
    modalContainerEl.id = id;
    rootEl.appendChild(modalContainerEl);
  }

  function _default(App) {
    var emberModalDialog = App.emberModalDialog || {};
    var modalContainerElId = emberModalDialog.modalRootElementId || 'modal-overlays';
    App.register('config:modals-container-id', Ember.testing ? 'ember-testing' : modalContainerElId, {
      instantiate: false
    });
    App.inject('service:modal-dialog', 'destinationElementId', 'config:modals-container-id');
    appendContainerElement(App.rootElement, modalContainerElId);
  }
});