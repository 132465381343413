define("ember-math-helpers/helpers/gcd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gcd = gcd;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Returns the greatest positive integer that divides each of two integers
   *
   * ```hbs
   * {{gcd a b}}
   * ```
   *
   * @param {number} number1 The first number
   * @param {number} number2 The second number
   * @return {number} The GCD of the two numbers passed
   */
  function gcd(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        _ref2$ = _ref2[0],
        number1 = _ref2$ === void 0 ? 0 : _ref2$,
        _ref2$2 = _ref2[1],
        number2 = _ref2$2 === void 0 ? 0 : _ref2$2;

    var a = Math.abs(number1);
    var b = Math.abs(number2);

    if (a === 0) {
      return b;
    }

    if (b === 0) {
      return a;
    }

    return gcd([b, a % b]);
  }

  var _default = Ember.Helper.helper(gcd);

  _exports.default = _default;
});