define("ember-lifeline/poll-task", ["exports", "ember-lifeline/utils/get-task", "ember-lifeline/utils/disposable"], function (_exports, _getTask, _disposable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._setRegisteredPollers = _setRegisteredPollers;
  _exports.setShouldPoll = setShouldPoll;
  _exports.pollTaskFor = pollTaskFor;
  _exports.pollTask = pollTask;
  _exports.cancelPoll = cancelPoll;

  /**
   * A map of instances/poller functions that allows us to
   * store poller tokens per instance.
   *
   * @private
   *
   */
  var registeredPollers = new WeakMap();
  /**
   * Test use only. Allows for swapping out the WeakMap to a Map, giving
   * us the ability to detect whether the pollers set is empty.
   *
   * @private
   * @param {*} mapForTesting A map used to ensure correctness when testing.
   */

  function _setRegisteredPollers(mapForTesting) {
    registeredPollers = mapForTesting;
  }

  var token = 0;

  var _shouldPollOverride;

  function shouldPoll() {
    if (_shouldPollOverride) {
      return _shouldPollOverride();
    } // eslint-disable-next-line ember-suave/no-direct-property-access


    return !Ember.testing;
  }

  function setShouldPoll(callback) {
    _shouldPollOverride = callback;
  }

  var queuedPollTasks = Object.create(null);

  function pollTaskFor(token) {
    (false && !(!!queuedPollTasks[token]) && Ember.assert("You cannot advance pollTask '".concat(token, "' when `next` has not been called."), !!queuedPollTasks[token]));
    return Ember.run.join(null, queuedPollTasks[token]);
  }
  /**
     Sets up a function that can perform polling logic in a testing safe way.
     The task is invoked synchronously with an argument (generally called `next`).
     In normal development/production when `next` is invoked, it will trigger the
     task again (recursively). However, when in test mode the recursive polling
     functionality is disabled, and usage of the `pollTaskFor` helper is required.
  
     Example:
  
     ```js
    import { pollTask, runTask, runDisposables } from 'ember-lifeline';
  
    export default Component.extend({
       api: injectService(),
  
       init() {
         this._super(...arguments);
  
         let token = pollTask(this, (next) => {
           this.get('api').request('get', 'some/path')
             .then(() => {
               runTask(this, next, 1800);
             })
         });
  
         this._pollToken = token;
       },
  
       willDestroy() {
         this._super(...arguments);
  
         runDisposables(this);
       }
     });
     ```
  
     Test Example:
  
     ```js
     import wait from 'ember-test-helpers/wait';
     import { pollTaskFor } from 'ember-lifeline';
  
     //...snip...
  
     test('foo-bar watches things', async function(assert) {
       await render(hbs`{{foo-bar}}`);
  
       return wait()
         .then(() => {
           assert.equal(serverRequests, 1, 'called initially');
  
           pollTaskFor(this._pollToken);
           return wait();
         })
         .then(() => {
           assert.equal(serverRequests, 2, 'called again');
         });
     });
     ```
  
     @method pollTask
     @param { IDestroyable } destroyable the entangled object that was provided with the original *Task call
     @param { Function | String } taskOrName a function representing the task, or string
                                             specifying a property representing the task,
                                             which is run at the provided time specified
                                             by timeout
     @param { Token } token the Token for the pollTask, either a String or Number
     @public
     */


  function pollTask(destroyable, taskOrName) {
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : getNextToken();
    var next;
    var task = (0, _getTask.default)(destroyable, taskOrName, 'pollTask');

    var tick = function tick() {
      return task.call(destroyable, next);
    };

    var pollers = registeredPollers.get(destroyable);

    if (!pollers) {
      pollers = new Set();
      registeredPollers.set(destroyable, pollers);
      (0, _disposable.registerDisposable)(destroyable, getPollersDisposable(destroyable, pollers));
    }

    pollers.add(token);

    if (shouldPoll()) {
      next = tick;
    } else {
      next = function next() {
        queuedPollTasks[token] = tick;
      };
    }

    task.call(destroyable, next);
    return token;
  }

  function cancelPoll(destroyable, _token) {
    var token;

    if (typeof destroyable === 'number' || typeof destroyable === 'string') {
      Ember.deprecate('ember-lifeline cancelPoll called without an object. New syntax is cancelPoll(destroyable, cancelId) and avoids a memory leak.', true, {
        id: 'ember-lifeline-cancel-poll-without-object',
        until: '4.0.0'
      });
      token = destroyable;
    } else {
      var pollers = registeredPollers.get(destroyable);
      token = _token;

      if (pollers !== undefined) {
        pollers.delete(token);
      }
    }

    delete queuedPollTasks[token];
  }

  function getPollersDisposable(destroyable, pollers) {
    return function () {
      pollers.forEach(function (token) {
        cancelPoll(destroyable, token);
      });
    };
  }

  function getNextToken() {
    return token++;
  }
});