define("ember-light-table/templates/components/lt-scrollable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tfCJPbKU",
    "block": "{\"symbols\":[\"scrollbar\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"virtualScrollbar\"]]],null,{\"statements\":[[4,\"ember-scrollable\",null,[[\"classNames\",\"autoHide\",\"horizontal\",\"vertical\",\"scrollToY\",\"onScrollY\"],[\"lt-scrollable\",[23,[\"autoHide\"]],[23,[\"horizontal\"]],[23,[\"vertical\"]],[23,[\"scrollTo\"]],[23,[\"onScrollY\"]]]],{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-scrollable.hbs"
    }
  });

  _exports.default = _default;
});