define("ember-power-calendar/components/power-calendar/days", ["exports", "ember-power-calendar/templates/components/power-calendar/days", "ember-power-calendar-utils"], function (_exports, _days, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  var _default = Ember.Component.extend({
    layout: _days.default,
    focusedId: null,
    showDaysAround: true,
    classNames: ['ember-power-calendar-days'],
    weekdayFormat: 'short',
    // "min" | "short" | "long"
    powerCalendarService: Ember.inject.service('power-calendar'),
    attributeBindings: ['data-power-calendar-id'],
    // CPs
    'data-power-calendar-id': Ember.computed.oneWay('calendar.uniqueId'),
    weekdaysMin: Ember.computed('calendar.locale', function () {
      return (0, _emberPowerCalendarUtils.withLocale)(this.get("calendar.locale"), _emberPowerCalendarUtils.getWeekdaysMin);
    }),
    weekdaysShort: Ember.computed('calendar.locale', function () {
      return (0, _emberPowerCalendarUtils.withLocale)(this.get("calendar.locale"), _emberPowerCalendarUtils.getWeekdaysShort);
    }),
    weekdays: Ember.computed('calendar.locale', function () {
      return (0, _emberPowerCalendarUtils.withLocale)(this.get("calendar.locale"), _emberPowerCalendarUtils.getWeekdays);
    }),
    localeStartOfWeek: Ember.computed('weekdaysShort', 'startOfWeek', function () {
      var forcedStartOfWeek = this.get('startOfWeek');

      if (forcedStartOfWeek) {
        return parseInt(forcedStartOfWeek, 10);
      }

      return (0, _emberPowerCalendarUtils.localeStartOfWeek)(this.get('calendar.locale'));
    }),
    weekdaysNames: Ember.computed('localeStartOfWeek', 'weekdayFormat', 'calendar.locale', function () {
      var _this$getProperties = this.getProperties('localeStartOfWeek', 'weekdayFormat'),
          localeStartOfWeek = _this$getProperties.localeStartOfWeek,
          weekdayFormat = _this$getProperties.weekdayFormat;

      var format = "weekdays".concat(weekdayFormat === 'long' ? '' : weekdayFormat === 'min' ? 'Min' : 'Short');
      var weekdaysNames = this.get(format);
      return weekdaysNames.slice(localeStartOfWeek).concat(weekdaysNames.slice(0, localeStartOfWeek));
    }),
    days: Ember.computed('calendar', 'focusedId', 'localeStartOfWeek', 'minDate', 'maxDate', 'disabledDates.[]', 'maxLength', function () {
      var today = this.get('powerCalendarService').getDate();
      var calendar = this.get('calendar');
      var lastDay = this.lastDay();
      var day = this.firstDay();
      var days = [];

      while ((0, _emberPowerCalendarUtils.isBefore)(day, lastDay)) {
        days.push(this.buildDay(day, today, calendar));
        day = (0, _emberPowerCalendarUtils.add)(day, 1, "day");
      }

      return days;
    }),
    weeks: Ember.computed('showDaysAround', 'days', function () {
      var _this$getProperties2 = this.getProperties('showDaysAround', 'days'),
          showDaysAround = _this$getProperties2.showDaysAround,
          days = _this$getProperties2.days;

      var weeks = [];
      var i = 0;

      while (days[i]) {
        var daysOfWeek = days.slice(i, i + 7);

        if (!showDaysAround) {
          daysOfWeek = daysOfWeek.filter(function (d) {
            return d.isCurrentMonth;
          });
        }

        weeks.push({
          id: "week-of-".concat(daysOfWeek[0].id),
          days: daysOfWeek,
          missingDays: 7 - daysOfWeek.length
        });
        i += 7;
      }

      return weeks;
    }),
    center: null,
    currentCenter: Ember.computed('center', 'calendar.center', function () {
      var center = this.get('center');

      if (!center) {
        center = this.get('selected') || this.get('calendar.center');
      }

      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }),
    // Lifecycle hooks
    init: function init() {
      this._super.apply(this, arguments);

      this._handleDayClick = this._handleDayClick.bind(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.addEventListener('click', this._handleDayClick);
    },
    willRemoveElement: function willRemoveElement() {
      this._super.apply(this, arguments);

      this.element.removeEventListener('click', this._handleDayClick);
    },
    // Actions
    actions: {
      onFocusDay: function onFocusDay(day) {
        Ember.run.scheduleOnce('actions', this, this._updateFocused, day.id);
      },
      onBlurDay: function onBlurDay() {
        Ember.run.scheduleOnce('actions', this, this._updateFocused, null);
      },
      onKeyDown: function onKeyDown(calendar, e) {
        var focusedId = this.get('focusedId');

        if (focusedId) {
          var days = this.get('days');
          var day, index;

          for (var i = 0; i < days.length; i++) {
            if (days[i].id === focusedId) {
              index = i;
              break;
            }
          }

          if (e.keyCode === 38) {
            e.preventDefault();
            var newIndex = Math.max(index - 7, 0);
            day = days[newIndex];

            if (day.isDisabled) {
              for (var _i = newIndex + 1; _i <= index; _i++) {
                day = days[_i];

                if (!day.isDisabled) {
                  break;
                }
              }
            }
          } else if (e.keyCode === 40) {
            e.preventDefault();

            var _newIndex = Math.min(index + 7, days.length - 1);

            day = days[_newIndex];

            if (day.isDisabled) {
              for (var _i2 = _newIndex - 1; _i2 >= index; _i2--) {
                day = days[_i2];

                if (!day.isDisabled) {
                  break;
                }
              }
            }
          } else if (e.keyCode === 37) {
            day = days[Math.max(index - 1, 0)];

            if (day.isDisabled) {
              return;
            }
          } else if (e.keyCode === 39) {
            day = days[Math.min(index + 1, days.length - 1)];

            if (day.isDisabled) {
              return;
            }
          } else {
            return;
          }

          this.set('focusedId', day.id);
          Ember.run.scheduleOnce('afterRender', this, '_focusDate', day.id);
        }
      }
    },
    // Methods
    buildDay: function buildDay(date, today, calendar) {
      var id = (0, _emberPowerCalendarUtils.formatDate)(date, 'YYYY-MM-DD');
      return (0, _emberPowerCalendarUtils.normalizeCalendarDay)({
        id: id,
        number: date.getDate(),
        date: new Date(date),
        isDisabled: this.dayIsDisabled(date),
        isFocused: this.get('focusedId') === id,
        isCurrentMonth: date.getMonth() === this.get('currentCenter').getMonth(),
        isToday: (0, _emberPowerCalendarUtils.isSame)(date, today, 'day'),
        isSelected: this.dayIsSelected(date, calendar)
      });
    },
    buildonSelectValue: function buildonSelectValue(day) {
      return day;
    },
    dayIsSelected: function dayIsSelected(date) {
      var calendar = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('calendar');
      return calendar.selected ? (0, _emberPowerCalendarUtils.isSame)(date, calendar.selected, 'day') : false;
    },
    dayIsDisabled: function dayIsDisabled(date) {
      var isDisabled = !this.get('calendar.actions.select');

      if (isDisabled) {
        return true;
      }

      var minDate = this.get('minDate');

      if (minDate && (0, _emberPowerCalendarUtils.isBefore)(date, minDate)) {
        return true;
      }

      var maxDate = this.get('maxDate');

      if (maxDate && (0, _emberPowerCalendarUtils.isAfter)(date, maxDate)) {
        return true;
      }

      var disabledDates = this.get('disabledDates');

      if (disabledDates) {
        var disabledInRange = disabledDates.some(function (d) {
          var isSameDay = (0, _emberPowerCalendarUtils.isSame)(date, d, 'day');
          var isWeekDayIncludes = WEEK_DAYS.indexOf(d) !== -1 && (0, _emberPowerCalendarUtils.formatDate)(date, 'ddd') === d;
          return isSameDay || isWeekDayIncludes;
        });

        if (disabledInRange) {
          return true;
        }
      }

      return false;
    },
    firstDay: function firstDay() {
      var firstDay = (0, _emberPowerCalendarUtils.startOf)(this.get('currentCenter'), 'month');
      return (0, _emberPowerCalendarUtils.startOfWeek)(firstDay, this.get('localeStartOfWeek'));
    },
    lastDay: function lastDay() {
      var localeStartOfWeek = this.get('localeStartOfWeek');
      (false && !(!isNaN(this.get('currentCenter').getTime())) && Ember.assert("The center of the calendar is an invalid date.", !isNaN(this.get('currentCenter').getTime())));
      var lastDay = (0, _emberPowerCalendarUtils.endOf)(this.get('currentCenter'), 'month');
      return (0, _emberPowerCalendarUtils.endOfWeek)(lastDay, localeStartOfWeek);
    },
    _updateFocused: function _updateFocused(id) {
      this.set('focusedId', id);
    },
    _focusDate: function _focusDate(id) {
      var dayElement = this.element.querySelector("[data-date=\"".concat(id, "\"]"));

      if (dayElement) {
        dayElement.focus();
      }
    },
    _handleDayClick: function _handleDayClick(e) {
      var dayEl = e.target.closest('[data-date]');

      if (dayEl) {
        var dateStr = dayEl.dataset.date;
        var day = this.get('days').find(function (d) {
          return d.id === dateStr;
        });

        if (day) {
          var calendar = this.get('calendar');

          if (calendar.actions.select) {
            calendar.actions.select(day, calendar, e);
          }
        }
      }
    }
  });

  _exports.default = _default;
});