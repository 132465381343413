define("ember-composable-helpers/helpers/union", ["exports", "ember-composable-helpers/-private/create-multi-array-helper"], function (_exports, _createMultiArrayHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _createMultiArrayHelper.default)(Ember.computed.union);

  _exports.default = _default;
});