define("ember-composable-helpers/helpers/pipe-action", ["exports", "ember-composable-helpers/helpers/pipe", "ember-composable-helpers/-private/closure-action"], function (_exports, _pipe, _closureAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var closurePipe = _pipe.pipe;

  if (_closureAction.default) {
    closurePipe[_closureAction.default] = true;
  }

  var _default = Ember.Helper.helper(closurePipe);

  _exports.default = _default;
});