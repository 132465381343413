define('ember-gestures/utils/string/dasherized-to-words', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = dasherizedToWords;
  function dasherizedToWords(s) {
    return s.replace(/-/g, ' ');
  }
});