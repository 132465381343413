define('ember-gestures/recognizers/vertical-swipe', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    include: [],
    exclude: [],
    eventName: 'swipe',
    options: { threshold: 25, direction: typeof Hammer === 'undefined' ? '' : Hammer.DIRECTION_VERTICAL },
    recognizer: 'swipe'
  };
});