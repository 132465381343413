define('ember-cli-deploy-sentry/services/raven', ['exports', 'ember-cli-sentry/services/raven'], function (exports, _raven) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _raven.default.extend({
        releaseMetaName: 'sentry:revision',
        release: Ember.computed('releaseMetaName', {
            get: function get() {
                return Ember.$('meta[name=\'' + this.get('releaseMetaName') + '\']').attr('content');
            }
        })
    });
});