define("ember-font-awesome/templates/components/fa-stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eJaHCwE6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"stack-1x\",\"stack-2x\"],[[27,\"component\",[\"fa-icon\"],[[\"stack\"],[\"1\"]]],[27,\"component\",[\"fa-icon\"],[[\"stack\"],[\"2\"]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-font-awesome/templates/components/fa-stack.hbs"
    }
  });

  _exports.default = _default;
});