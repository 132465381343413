define("ember-light-table/templates/components/cells/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bnn/ILsy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"column\",\"cellComponent\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[23,[\"column\",\"cellComponent\"]]],[[\"tableActions\",\"extra\",\"table\",\"column\",\"row\",\"value\",\"rawValue\"],[[23,[\"tableActions\"]],[23,[\"extra\"]],[23,[\"table\"]],[23,[\"column\"]],[23,[\"row\"]],[23,[\"value\"]],[23,[\"rawValue\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/cells/base.hbs"
    }
  });

  _exports.default = _default;
});