define('ember-light-table/utils/css-styleify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = cssStyleify;
  function cssStyleify() {
    var hash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var styles = [];

    Object.keys(hash).forEach(function (key) {
      var value = hash[key];

      if (Ember.isPresent(value)) {
        styles.push(Ember.String.dasherize(key) + ': ' + value);
      }
    });

    return Ember.String.htmlSafe(styles.join('; '));
  }
});