define("ember-light-table/templates/components/lt-foot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nJhtcAmc",
    "block": "{\"symbols\":[\"column\",\"column\",\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\"],[[27,\"concat\",[[23,[\"tableId\"]],\"_inline_foot\"],null],[23,[\"renderInPlace\"]]]],{\"statements\":[[0,\"  \"],[7,\"table\"],[12,\"class\",[21,\"tableClassNames\"]],[9],[0,\"\\n    \"],[7,\"tfoot\"],[11,\"class\",\"lt-foot\"],[9],[0,\"\\n\"],[0,\"      \"],[7,\"tr\"],[11,\"class\",\"lt-scaffolding-row\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"columns\"]]],null,{\"statements\":[[0,\"          \"],[7,\"td\"],[12,\"style\",[27,\"html-safe\",[[27,\"if\",[[22,2,[\"width\"]],[27,\"concat\",[\"width: \",[22,2,[\"width\"]]],null]],null]],null]],[11,\"class\",\"lt-scaffolding\"],[9],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[10],[0,\"\\n\"],[4,\"if\",[[24,3]],null,{\"statements\":[[0,\"        \"],[14,3,[[23,[\"columns\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"tr\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"columns\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"component\",[[27,\"concat\",[\"light-table/columns/\",[22,1,[\"type\"]]],null],[22,1,[]]],[[\"table\",\"tableActions\",\"extra\",\"sortIcons\",\"resizeOnDrag\",\"click\",\"doubleClick\",\"onColumnResized\",\"onColumnDrag\",\"onColumnDrop\"],[[23,[\"table\"]],[23,[\"tableActions\"]],[23,[\"extra\"]],[23,[\"sortIcons\"]],[23,[\"resizeOnDrag\"]],[27,\"action\",[[22,0,[]],\"onColumnClick\",[22,1,[]]],null],[27,\"action\",[[22,0,[]],\"onColumnDoubleClick\",[22,1,[]]],null],[27,\"action\",[[22,0,[]],\"onColumnResized\"],null],[27,\"action\",[[22,0,[]],\"onColumnDrag\"],null],[27,\"action\",[[22,0,[]],\"onColumnDrop\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-foot.hbs"
    }
  });

  _exports.default = _default;
});