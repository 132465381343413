define("ember-parachute/-private/parachute-meta", ["exports", "ember-parachute/-private/query-param", "ember-parachute/-private/symbols"], function (_exports, _queryParam, _symbols) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  var keys = Object.keys;
  /**
   * Meta class used by ember-parachute.
   *
   * @export
   * @class ParachuteMeta
   */

  var ParachuteMeta =
  /**
   * Creates an instance of ParachuteMeta.
   *
   * @param {Object} [queryParams={}]
   * @memberof ParachuteMeta
   */
  function ParachuteMeta() {
    var _this = this;

    var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    _classCallCheck(this, ParachuteMeta);

    this.queryParams = keys(queryParams).reduce(function (qps, key) {
      qps[key] = new _queryParam.default(key, queryParams[key]);
      return qps;
    }, {});
    this.queryParamsArray = Ember.A(keys(this.queryParams).map(function (key) {
      return _this.queryParams[key];
    }));
    this.qpMapForController = this.queryParamsArray.reduce(function (qps, _ref) {
      var key = _ref.key,
          as = _ref.as,
          scope = _ref.scope;
      qps[key] = {
        as: as,
        scope: scope
      };
      return qps;
    }, {}); // Meta info used by the decorators

    Object.defineProperty(this.qpMapForController, _symbols.PARACHUTE_QPS, {
      value: true
    });
    this.qpMapForRoute = this.queryParamsArray.reduce(function (qps, _ref2) {
      var key = _ref2.key,
          replace = _ref2.replace;
      qps[key] = {
        replace: replace
      };
      return qps;
    }, {});
    this.defaultValues = this.queryParamsArray.reduce(function (defaults, _ref3) {
      var key = _ref3.key,
          defaultValue = _ref3.defaultValue;
      defaults[key] = defaultValue;
      return defaults;
    }, {});
  };

  _exports.default = ParachuteMeta;
});