define("ember-parachute/-private/parachute-event", ["exports", "ember-parachute/query-params"], function (_exports, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  var canInvoke = Ember.canInvoke;
  var keys = Object.keys;
  /**
   * Change event generated by query params changing.
   *
   * @export
   * @class ParachuteEvent
   */

  var ParachuteEvent =
  /**
   * Creates an instance of ParachuteEvent.
   *
   * @param {string} routeName
   * @param {Ember.Controller} controller
   * @param {object} [changed={}]
   *
   * @memberof ParachuteEvent
   */
  function ParachuteEvent(routeName, controller) {
    var changed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    _classCallCheck(this, ParachuteEvent);

    var _QueryParams$metaFor = _queryParams.default.metaFor(controller),
        queryParams = _QueryParams$metaFor.queryParams,
        queryParamsArray = _QueryParams$metaFor.queryParamsArray;

    var state = _queryParams.default.stateFor(controller);

    var changedKeys = Ember.A(keys(changed));
    /**
     * The route the event was fired from
     */

    this.routeName = routeName;
    /**
     * All query params that have changed from this update event
     */

    this.changed = queryParamsArray.reduce(function (changedParams, qp) {
      if (changedKeys.includes(qp.as)) {
        changedParams[qp.key] = canInvoke(qp, 'deserialize') ? qp.deserialize(changed[qp.as], controller) : changed[qp.as];
      }

      return changedParams;
    }, {}, undefined);
    /**
     * All Query Params at this given moment
     */

    this.queryParams = _queryParams.default.queryParamsFor(controller);
    /**
     * Whether or not a model refresh should occur
     */

    this.shouldRefresh = Ember.A(keys(this.changed)).any(function (key) {
      return queryParams[key].refresh;
    });
    /**
     * All query params that are not their default
     */

    this.changes = keys(state).reduce(function (changes, key) {
      if (state[key].changed) {
        changes[key] = state[key].value;
      }

      return changes;
    }, {});
  };

  _exports.default = ParachuteEvent;
});