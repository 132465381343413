enifed("@ember/object/lib/computed/reduce_computed_macros", ["exports", "@ember/debug", "@ember/-internals/metal", "@ember/-internals/runtime"], function (_exports, _debug, _metal, _runtime) {
  "use strict";

  _exports.sum = sum;
  _exports.max = max;
  _exports.min = min;
  _exports.map = map;
  _exports.mapBy = mapBy;
  _exports.filter = filter;
  _exports.filterBy = filterBy;
  _exports.uniq = uniq;
  _exports.uniqBy = uniqBy;
  _exports.intersect = intersect;
  _exports.setDiff = setDiff;
  _exports.collect = collect;
  _exports.sort = sort;
  _exports.union = void 0;

  /**
  @module @ember/object
  */
  function reduceMacro(dependentKey, callback, initialValue, name) {
    false && !!/[\[\]\{\}]/g.test(dependentKey) && (0, _debug.assert)("Dependent key passed to `computed." + name + "` shouldn't contain brace expanding pattern.", !/[\[\]\{\}]/g.test(dependentKey));
    var cp = new _metal.ComputedProperty(function () {
      var arr = (0, _metal.get)(this, dependentKey);

      if (arr === null || typeof arr !== 'object') {
        return initialValue;
      }

      return arr.reduce(callback, initialValue, this);
    }, {
      dependentKeys: [dependentKey + ".[]"],
      readOnly: true
    });
    return cp;
  }

  function arrayMacro(dependentKey, callback) {
    // This is a bit ugly
    var propertyName;

    if (/@each/.test(dependentKey)) {
      propertyName = dependentKey.replace(/\.@each.*$/, '');
    } else {
      propertyName = dependentKey;
      dependentKey += '.[]';
    }

    var cp = new _metal.ComputedProperty(function () {
      var value = (0, _metal.get)(this, propertyName);

      if ((0, _runtime.isArray)(value)) {
        return (0, _runtime.A)(callback.call(this, value));
      } else {
        return (0, _runtime.A)();
      }
    }, {
      readOnly: true
    });
    cp.property(dependentKey); // this forces to expand properties GH #15855

    return cp;
  }

  function multiArrayMacro(_dependentKeys, callback, name) {
    false && !_dependentKeys.every(function (dependentKey) {
      return !/[\[\]\{\}]/g.test(dependentKey);
    }) && (0, _debug.assert)("Dependent keys passed to `computed." + name + "` shouldn't contain brace expanding pattern.", _dependentKeys.every(function (dependentKey) {
      return !/[\[\]\{\}]/g.test(dependentKey);
    }));

    var dependentKeys = _dependentKeys.map(function (key) {
      return key + ".[]";
    });

    var cp = new _metal.ComputedProperty(function () {
      return (0, _runtime.A)(callback.call(this, _dependentKeys));
    }, {
      dependentKeys: dependentKeys,
      readOnly: true
    });
    return cp;
  }
  /**
    A computed property that returns the sum of the values
    in the dependent array.
  
    @method sum
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @return {ComputedProperty} computes the sum of all values in the dependentKey's array
    @since 1.4.0
    @public
  */


  function sum(dependentKey) {
    return reduceMacro(dependentKey, function (sum, item) {
      return sum + item;
    }, 0, 'sum');
  }
  /**
    A computed property that calculates the maximum value in the
    dependent array. This will return `-Infinity` when the dependent
    array is empty.
  
    ```javascript
    import { mapBy, max } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let Person = EmberObject.extend({
      childAges: mapBy('children', 'age'),
      maxChildAge: max('childAges')
    });
  
    let lordByron = Person.create({ children: [] });
  
    lordByron.get('maxChildAge'); // -Infinity
    lordByron.get('children').pushObject({
      name: 'Augusta Ada Byron', age: 7
    });
    lordByron.get('maxChildAge'); // 7
    lordByron.get('children').pushObjects([{
      name: 'Allegra Byron',
      age: 5
    }, {
      name: 'Elizabeth Medora Leigh',
      age: 8
    }]);
    lordByron.get('maxChildAge'); // 8
    ```
  
    If the types of the arguments are not numbers,
    they will be converted to numbers and the type
    of the return value will always be `Number`.
    For example, the max of a list of Date objects will be
    the highest timestamp as a `Number`.
    This behavior is consistent with `Math.max`.
  
    @method max
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @return {ComputedProperty} computes the largest value in the dependentKey's array
    @public
  */


  function max(dependentKey) {
    return reduceMacro(dependentKey, function (max, item) {
      return Math.max(max, item);
    }, -Infinity, 'max');
  }
  /**
    A computed property that calculates the minimum value in the
    dependent array. This will return `Infinity` when the dependent
    array is empty.
  
    ```javascript
    import { mapBy, min } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let Person = EmberObject.extend({
      childAges: mapBy('children', 'age'),
      minChildAge: min('childAges')
    });
  
    let lordByron = Person.create({ children: [] });
  
    lordByron.get('minChildAge'); // Infinity
    lordByron.get('children').pushObject({
      name: 'Augusta Ada Byron', age: 7
    });
    lordByron.get('minChildAge'); // 7
    lordByron.get('children').pushObjects([{
      name: 'Allegra Byron',
      age: 5
    }, {
      name: 'Elizabeth Medora Leigh',
      age: 8
    }]);
    lordByron.get('minChildAge'); // 5
    ```
  
    If the types of the arguments are not numbers,
    they will be converted to numbers and the type
    of the return value will always be `Number`.
    For example, the min of a list of Date objects will be
    the lowest timestamp as a `Number`.
    This behavior is consistent with `Math.min`.
  
    @method min
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @return {ComputedProperty} computes the smallest value in the dependentKey's array
    @public
  */


  function min(dependentKey) {
    return reduceMacro(dependentKey, function (min, item) {
      return Math.min(min, item);
    }, Infinity, 'min');
  }
  /**
    Returns an array mapped via the callback
  
    The callback method you provide should have the following signature.
    `item` is the current item in the iteration.
    `index` is the integer index of the current item in the iteration.
  
    ```javascript
    function(item, index);
    ```
  
    Example
  
    ```javascript
    import { map } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let Hamster = EmberObject.extend({
      excitingChores: map('chores', function(chore, index) {
        return chore.toUpperCase() + '!';
      })
    });
  
    let hamster = Hamster.create({
      chores: ['clean', 'write more unit tests']
    });
  
    hamster.get('excitingChores'); // ['CLEAN!', 'WRITE MORE UNIT TESTS!']
    ```
  
    @method map
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @param {Function} callback
    @return {ComputedProperty} an array mapped via the callback
    @public
  */


  function map(dependentKey, callback) {
    return arrayMacro(dependentKey, function (value) {
      return value.map(callback, this);
    });
  }
  /**
    Returns an array mapped to the specified key.
  
    ```javascript
    import { mapBy } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let Person = EmberObject.extend({
      childAges: mapBy('children', 'age')
    });
  
    let lordByron = Person.create({ children: [] });
  
    lordByron.get('childAges'); // []
    lordByron.get('children').pushObject({ name: 'Augusta Ada Byron', age: 7 });
    lordByron.get('childAges'); // [7]
    lordByron.get('children').pushObjects([{
      name: 'Allegra Byron',
      age: 5
    }, {
      name: 'Elizabeth Medora Leigh',
      age: 8
    }]);
    lordByron.get('childAges'); // [7, 5, 8]
    ```
  
    @method mapBy
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @param {String} propertyKey
    @return {ComputedProperty} an array mapped to the specified key
    @public
  */


  function mapBy(dependentKey, propertyKey) {
    false && !(typeof propertyKey === 'string') && (0, _debug.assert)('`computed.mapBy` expects a property string for its second argument, ' + 'perhaps you meant to use "map"', typeof propertyKey === 'string');
    false && !!/[\[\]\{\}]/g.test(dependentKey) && (0, _debug.assert)("Dependent key passed to `computed.mapBy` shouldn't contain brace expanding pattern.", !/[\[\]\{\}]/g.test(dependentKey));
    return map(dependentKey + ".@each." + propertyKey, function (item) {
      return (0, _metal.get)(item, propertyKey);
    });
  }
  /**
    Filters the array by the callback.
  
    The callback method you provide should have the following signature.
    `item` is the current item in the iteration.
    `index` is the integer index of the current item in the iteration.
    `array` is the dependant array itself.
  
    ```javascript
    function(item, index, array);
    ```
  
    ```javascript
    import { filter } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let Hamster = EmberObject.extend({
      remainingChores: filter('chores', function(chore, index, array) {
        return !chore.done;
      })
    });
  
    let hamster = Hamster.create({
      chores: [
        { name: 'cook', done: true },
        { name: 'clean', done: true },
        { name: 'write more unit tests', done: false }
      ]
    });
  
    hamster.get('remainingChores'); // [{name: 'write more unit tests', done: false}]
    ```
  
    You can also use `@each.property` in your dependent key, the callback will still use the underlying array:
  
    ```javascript
    import { A } from '@ember/array';
    import { filter } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let Hamster = EmberObject.extend({
      remainingChores: filter('chores.@each.done', function(chore, index, array) {
        return !chore.get('done');
      })
    });
  
    let hamster = Hamster.create({
      chores: A([
        EmberObject.create({ name: 'cook', done: true }),
        EmberObject.create({ name: 'clean', done: true }),
        EmberObject.create({ name: 'write more unit tests', done: false })
      ])
    });
    hamster.get('remainingChores'); // [{name: 'write more unit tests', done: false}]
    hamster.get('chores').objectAt(2).set('done', true);
    hamster.get('remainingChores'); // []
    ```
  
  
    @method filter
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @param {Function} callback
    @return {ComputedProperty} the filtered array
    @public
  */


  function filter(dependentKey, callback) {
    return arrayMacro(dependentKey, function (value) {
      return value.filter(callback, this);
    });
  }
  /**
    Filters the array by the property and value
  
    ```javascript
    import { filterBy } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let Hamster = EmberObject.extend({
      remainingChores: filterBy('chores', 'done', false)
    });
  
    let hamster = Hamster.create({
      chores: [
        { name: 'cook', done: true },
        { name: 'clean', done: true },
        { name: 'write more unit tests', done: false }
      ]
    });
  
    hamster.get('remainingChores'); // [{ name: 'write more unit tests', done: false }]
    ```
  
    @method filterBy
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @param {String} propertyKey
    @param {*} value
    @return {ComputedProperty} the filtered array
    @public
  */


  function filterBy(dependentKey, propertyKey, value) {
    false && !!/[\[\]\{\}]/g.test(dependentKey) && (0, _debug.assert)("Dependent key passed to `computed.filterBy` shouldn't contain brace expanding pattern.", !/[\[\]\{\}]/g.test(dependentKey));
    var callback;

    if (arguments.length === 2) {
      callback = function (item) {
        return (0, _metal.get)(item, propertyKey);
      };
    } else {
      callback = function (item) {
        return (0, _metal.get)(item, propertyKey) === value;
      };
    }

    return filter(dependentKey + ".@each." + propertyKey, callback);
  }
  /**
    A computed property which returns a new array with all the unique
    elements from one or more dependent arrays.
  
    Example
  
    ```javascript
    import { uniq } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let Hamster = EmberObject.extend({
      uniqueFruits: uniq('fruits')
    });
  
    let hamster = Hamster.create({
      fruits: [
        'banana',
        'grape',
        'kale',
        'banana'
      ]
    });
  
    hamster.get('uniqueFruits'); // ['banana', 'grape', 'kale']
    ```
  
    @method uniq
    @for @ember/object/computed
    @static
    @param {String} propertyKey*
    @return {ComputedProperty} computes a new array with all the
    unique elements from the dependent array
    @public
  */


  function uniq() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return multiArrayMacro(args, function (dependentKeys) {
      var _this = this;

      var uniq = (0, _runtime.A)();
      var seen = new Set();
      dependentKeys.forEach(function (dependentKey) {
        var value = (0, _metal.get)(_this, dependentKey);

        if ((0, _runtime.isArray)(value)) {
          value.forEach(function (item) {
            if (!seen.has(item)) {
              seen.add(item);
              uniq.push(item);
            }
          });
        }
      });
      return uniq;
    }, 'uniq');
  }
  /**
    A computed property which returns a new array with all the unique
    elements from an array, with uniqueness determined by specific key.
  
    Example
  
    ```javascript
    import { uniqBy } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let Hamster = EmberObject.extend({
      uniqueFruits: uniqBy('fruits', 'id')
    });
    let hamster = Hamster.create({
      fruits: [
        { id: 1, 'banana' },
        { id: 2, 'grape' },
        { id: 3, 'peach' },
        { id: 1, 'banana' }
      ]
    });
    hamster.get('uniqueFruits'); // [ { id: 1, 'banana' }, { id: 2, 'grape' }, { id: 3, 'peach' }]
    ```
  
    @method uniqBy
    @for @ember/object/computed
    @static
    @param {String} dependentKey
    @param {String} propertyKey
    @return {ComputedProperty} computes a new array with all the
    unique elements from the dependent array
    @public
  */


  function uniqBy(dependentKey, propertyKey) {
    false && !!/[\[\]\{\}]/g.test(dependentKey) && (0, _debug.assert)("Dependent key passed to `computed.uniqBy` shouldn't contain brace expanding pattern.", !/[\[\]\{\}]/g.test(dependentKey));
    var cp = new _metal.ComputedProperty(function () {
      var list = (0, _metal.get)(this, dependentKey);
      return (0, _runtime.isArray)(list) ? (0, _runtime.uniqBy)(list, propertyKey) : (0, _runtime.A)();
    }, {
      dependentKeys: [dependentKey + ".[]"],
      readOnly: true
    });
    return cp;
  }
  /**
    A computed property which returns a new array with all the unique
    elements from one or more dependent arrays.
  
    Example
  
    ```javascript
    import { union } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let Hamster = EmberObject.extend({
      uniqueFruits: union('fruits', 'vegetables')
    });
  
    let hamster = Hamster.create({
      fruits: [
        'banana',
        'grape',
        'kale',
        'banana',
        'tomato'
      ],
      vegetables: [
        'tomato',
        'carrot',
        'lettuce'
      ]
    });
  
    hamster.get('uniqueFruits'); // ['banana', 'grape', 'kale', 'tomato', 'carrot', 'lettuce']
    ```
  
    @method union
    @for @ember/object/computed
    @static
    @param {String} propertyKey*
    @return {ComputedProperty} computes a new array with all the
    unique elements from one or more dependent arrays.
    @public
  */


  var union = uniq;
  /**
    A computed property which returns a new array with all the elements
    two or more dependent arrays have in common.
  
    Example
  
    ```javascript
    import { intersect } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let obj = EmberObject.extend({
      friendsInCommon: intersect('adaFriends', 'charlesFriends')
    }).create({
      adaFriends: ['Charles Babbage', 'John Hobhouse', 'William King', 'Mary Somerville'],
      charlesFriends: ['William King', 'Mary Somerville', 'Ada Lovelace', 'George Peacock']
    });
  
    obj.get('friendsInCommon'); // ['William King', 'Mary Somerville']
    ```
  
    @method intersect
    @for @ember/object/computed
    @static
    @param {String} propertyKey*
    @return {ComputedProperty} computes a new array with all the
    duplicated elements from the dependent arrays
    @public
  */

  _exports.union = union;

  function intersect() {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return multiArrayMacro(args, function (dependentKeys) {
      var _this2 = this;

      var arrays = dependentKeys.map(function (dependentKey) {
        var array = (0, _metal.get)(_this2, dependentKey);
        return (0, _runtime.isArray)(array) ? array : [];
      });
      var results = arrays.pop().filter(function (candidate) {
        for (var i = 0; i < arrays.length; i++) {
          var found = false;
          var array = arrays[i];

          for (var j = 0; j < array.length; j++) {
            if (array[j] === candidate) {
              found = true;
              break;
            }
          }

          if (found === false) {
            return false;
          }
        }

        return true;
      });
      return (0, _runtime.A)(results);
    }, 'intersect');
  }
  /**
    A computed property which returns a new array with all the
    properties from the first dependent array that are not in the second
    dependent array.
  
    Example
  
    ```javascript
    import { setDiff } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let Hamster = EmberObject.extend({
      likes: ['banana', 'grape', 'kale'],
      wants: setDiff('likes', 'fruits')
    });
  
    let hamster = Hamster.create({
      fruits: [
        'grape',
        'kale',
      ]
    });
  
    hamster.get('wants'); // ['banana']
    ```
  
    @method setDiff
    @for @ember/object/computed
    @static
    @param {String} setAProperty
    @param {String} setBProperty
    @return {ComputedProperty} computes a new array with all the
    items from the first dependent array that are not in the second
    dependent array
    @public
  */


  function setDiff(setAProperty, setBProperty) {
    false && !(arguments.length === 2) && (0, _debug.assert)('`computed.setDiff` requires exactly two dependent arrays.', arguments.length === 2);
    false && !(!/[\[\]\{\}]/g.test(setAProperty) && !/[\[\]\{\}]/g.test(setBProperty)) && (0, _debug.assert)("Dependent keys passed to `computed.setDiff` shouldn't contain brace expanding pattern.", !/[\[\]\{\}]/g.test(setAProperty) && !/[\[\]\{\}]/g.test(setBProperty));
    var cp = new _metal.ComputedProperty(function () {
      var setA = this.get(setAProperty);
      var setB = this.get(setBProperty);

      if (!(0, _runtime.isArray)(setA)) {
        return (0, _runtime.A)();
      }

      if (!(0, _runtime.isArray)(setB)) {
        return (0, _runtime.A)(setA);
      }

      return setA.filter(function (x) {
        return setB.indexOf(x) === -1;
      });
    }, {
      dependentKeys: [setAProperty + ".[]", setBProperty + ".[]"],
      readOnly: true
    });
    return cp;
  }
  /**
    A computed property that returns the array of values
    for the provided dependent properties.
  
    Example
  
    ```javascript
    import { collect } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let Hamster = EmberObject.extend({
      clothes: collect('hat', 'shirt')
    });
  
    let hamster = Hamster.create();
  
    hamster.get('clothes'); // [null, null]
    hamster.set('hat', 'Camp Hat');
    hamster.set('shirt', 'Camp Shirt');
    hamster.get('clothes'); // ['Camp Hat', 'Camp Shirt']
    ```
  
    @method collect
    @for @ember/object/computed
    @static
    @param {String} dependentKey*
    @return {ComputedProperty} computed property which maps
    values of all passed in properties to an array.
    @public
  */


  function collect() {
    for (var _len3 = arguments.length, dependentKeys = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      dependentKeys[_key3] = arguments[_key3];
    }

    return multiArrayMacro(dependentKeys, function () {
      var _this3 = this;

      var res = dependentKeys.map(function (key) {
        var val = (0, _metal.get)(_this3, key);
        return val === undefined ? null : val;
      });
      return (0, _runtime.A)(res);
    }, 'collect');
  }
  /**
    A computed property which returns a new array with all the
    properties from the first dependent array sorted based on a property
    or sort function.
  
    The callback method you provide should have the following signature:
  
    ```javascript
    function(itemA, itemB);
    ```
  
    - `itemA` the first item to compare.
    - `itemB` the second item to compare.
  
    This function should return negative number (e.g. `-1`) when `itemA` should come before
    `itemB`. It should return positive number (e.g. `1`) when `itemA` should come after
    `itemB`. If the `itemA` and `itemB` are equal this function should return `0`.
  
    Therefore, if this function is comparing some numeric values, simple `itemA - itemB` or
    `itemA.get( 'foo' ) - itemB.get( 'foo' )` can be used instead of series of `if`.
  
    Example
  
    ```javascript
    import { sort } from '@ember/object/computed';
    import EmberObject from '@ember/object';
  
    let ToDoList = EmberObject.extend({
      // using standard ascending sort
      todosSorting: Object.freeze(['name']),
      sortedTodos: sort('todos', 'todosSorting'),
  
      // using descending sort
      todosSortingDesc: Object.freeze(['name:desc']),
      sortedTodosDesc: sort('todos', 'todosSortingDesc'),
  
      // using a custom sort function
      priorityTodos: sort('todos', function(a, b){
        if (a.priority > b.priority) {
          return 1;
        } else if (a.priority < b.priority) {
          return -1;
        }
  
        return 0;
      })
    });
  
    let todoList = ToDoList.create({todos: [
      { name: 'Unit Test', priority: 2 },
      { name: 'Documentation', priority: 3 },
      { name: 'Release', priority: 1 }
    ]});
  
    todoList.get('sortedTodos');      // [{ name:'Documentation', priority:3 }, { name:'Release', priority:1 }, { name:'Unit Test', priority:2 }]
    todoList.get('sortedTodosDesc');  // [{ name:'Unit Test', priority:2 }, { name:'Release', priority:1 }, { name:'Documentation', priority:3 }]
    todoList.get('priorityTodos');    // [{ name:'Release', priority:1 }, { name:'Unit Test', priority:2 }, { name:'Documentation', priority:3 }]
    ```
  
    @method sort
    @for @ember/object/computed
    @static
    @param {String} itemsKey
    @param {String or Function} sortDefinition a dependent key to an
    array of sort properties (add `:desc` to the arrays sort properties to sort descending) or a function to use when sorting
    @return {ComputedProperty} computes a new sorted array based
    on the sort property array or callback function
    @public
  */


  function sort(itemsKey, sortDefinition) {
    false && !(arguments.length === 2) && (0, _debug.assert)('`computed.sort` requires two arguments: an array key to sort and ' + 'either a sort properties key or sort function', arguments.length === 2);

    if (typeof sortDefinition === 'function') {
      return customSort(itemsKey, sortDefinition);
    } else {
      return propertySort(itemsKey, sortDefinition);
    }
  }

  function customSort(itemsKey, comparator) {
    return arrayMacro(itemsKey, function (value) {
      var _this4 = this;

      return value.slice().sort(function (x, y) {
        return comparator.call(_this4, x, y);
      });
    });
  } // This one needs to dynamically set up and tear down observers on the itemsKey
  // depending on the sortProperties


  function propertySort(itemsKey, sortPropertiesKey) {
    var cp = new _metal.ComputedProperty(function (key) {
      var _this5 = this;

      var sortProperties = (0, _metal.get)(this, sortPropertiesKey);
      false && !((0, _runtime.isArray)(sortProperties) && sortProperties.every(function (s) {
        return typeof s === 'string';
      })) && (0, _debug.assert)("The sort definition for '" + key + "' on " + this + " must be a function or an array of strings", (0, _runtime.isArray)(sortProperties) && sortProperties.every(function (s) {
        return typeof s === 'string';
      })); // Add/remove property observers as required.

      var activeObserversMap = cp._activeObserverMap || (cp._activeObserverMap = new WeakMap());
      var activeObservers = activeObserversMap.get(this);
      var sortPropertyDidChangeMap = cp._sortPropertyDidChangeMap || (cp._sortPropertyDidChangeMap = new WeakMap());

      if (!sortPropertyDidChangeMap.has(this)) {
        sortPropertyDidChangeMap.set(this, function () {
          this.notifyPropertyChange(key);
        });
      }

      var sortPropertyDidChange = sortPropertyDidChangeMap.get(this);

      if (activeObservers !== undefined) {
        activeObservers.forEach(function (path) {
          return (0, _metal.removeObserver)(_this5, path, sortPropertyDidChange);
        });
      }

      var itemsKeyIsAtThis = itemsKey === '@this';
      var normalizedSortProperties = normalizeSortProperties(sortProperties);

      if (normalizedSortProperties.length === 0) {
        var path = itemsKeyIsAtThis ? "[]" : itemsKey + ".[]";
        (0, _metal.addObserver)(this, path, sortPropertyDidChange);
        activeObservers = [path];
      } else {
        activeObservers = normalizedSortProperties.map(function (_ref) {
          var prop = _ref[0];
          var path = itemsKeyIsAtThis ? "@each." + prop : itemsKey + ".@each." + prop;
          (0, _metal.addObserver)(_this5, path, sortPropertyDidChange);
          return path;
        });
      }

      activeObserversMap.set(this, activeObservers);
      var items = itemsKeyIsAtThis ? this : (0, _metal.get)(this, itemsKey);

      if (!(0, _runtime.isArray)(items)) {
        return (0, _runtime.A)();
      }

      if (normalizedSortProperties.length === 0) {
        return (0, _runtime.A)(items.slice());
      } else {
        return sortByNormalizedSortProperties(items, normalizedSortProperties);
      }
    }, {
      dependentKeys: [sortPropertiesKey + ".[]"],
      readOnly: true
    });
    cp._activeObserverMap = undefined;
    cp._sortPropertyDidChangeMap = undefined;
    return cp;
  }

  function normalizeSortProperties(sortProperties) {
    return sortProperties.map(function (p) {
      var _p$split = p.split(':'),
          prop = _p$split[0],
          direction = _p$split[1];

      direction = direction || 'asc';
      return [prop, direction];
    });
  }

  function sortByNormalizedSortProperties(items, normalizedSortProperties) {
    return (0, _runtime.A)(items.slice().sort(function (itemA, itemB) {
      for (var i = 0; i < normalizedSortProperties.length; i++) {
        var _normalizedSortProper = normalizedSortProperties[i],
            prop = _normalizedSortProper[0],
            direction = _normalizedSortProper[1];
        var result = (0, _runtime.compare)((0, _metal.get)(itemA, prop), (0, _metal.get)(itemB, prop));

        if (result !== 0) {
          return direction === 'desc' ? -1 * result : result;
        }
      }

      return 0;
    }));
  }
});