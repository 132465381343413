define("ember-parachute/-private/symbols", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PARACHUTE_QPS = _exports.PARACHUTE_META = _exports.HAS_PARACHUTE = void 0;
  var HAS_PARACHUTE = '__has_parachute__';
  _exports.HAS_PARACHUTE = HAS_PARACHUTE;
  var PARACHUTE_META = '__parachute_meta__';
  _exports.PARACHUTE_META = PARACHUTE_META;
  var PARACHUTE_QPS = '__parachute_qps__';
  _exports.PARACHUTE_QPS = PARACHUTE_QPS;
});