define("ember-math-helpers/helpers/imul", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.imul = imul;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Executes `Math.imul` on the number passed to the helper.
   *
   * ```hbs
   * {{imul a}}
   * ```
   *
   * @param {number} number1 The first number to pass to `Math.imul`
   * @param {number} number2 The second number to pass to `Math.imul`
   * @return {number} The imul of the passed numbers
   */
  function imul(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        number1 = _ref2[0],
        number2 = _ref2[1];

    return Math.imul(number1, number2);
  }

  var _default = Ember.Helper.helper(imul);

  _exports.default = _default;
});