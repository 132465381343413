define("ember-gestures/templates/components/fast-async", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P3UkZJSu",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[23,[\"isPending\"]]]],[1,[21,\"text\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-gestures/templates/components/fast-async.hbs"
    }
  });

  _exports.default = _default;
});