define("ember-composable-helpers/helpers/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          callback = _ref2[0],
          array = _ref2[1];

      Ember.set(this, 'array', array);
      Ember.set(this, 'callback', callback);
      return Ember.get(this, 'content');
    },
    byPathDidChange: Ember.observer('callback', function () {
      var callback = Ember.get(this, 'callback');

      if (Ember.isEmpty(callback)) {
        Ember.defineProperty(this, 'content', []);
        return;
      }

      Ember.defineProperty(this, 'content', Ember.computed.map('array', callback));
    }),
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});