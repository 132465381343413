define('ember-light-table/index', ['exports', 'ember-light-table/classes/Table', 'ember-light-table/classes/Column', 'ember-light-table/classes/Row'], function (exports, _Table, _Column, _Row) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Row = exports.Column = exports.Table = undefined;
  exports.default = _Table.default;
  exports.Table = _Table.default;
  exports.Column = _Column.default;
  exports.Row = _Row.default;
});