define("ember-power-calendar/templates/components/power-calendar/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fnp20kSs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"nav\"],[11,\"class\",\"ember-power-calendar-nav\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"calendar\",\"actions\",\"changeCenter\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"ember-power-calendar-nav-control ember-power-calendar-nav-control--previous\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],[23,[\"calendar\",\"actions\",\"moveCenter\"]],-1,[23,[\"unit\"]],[23,[\"calendar\"]]],null]],[11,\"type\",\"button\"],[9],[0,\"«\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"ember-power-calendar-nav-title\"],[9],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"      \"],[14,1,[[23,[\"calendar\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"power-calendar-format-date\",[[23,[\"calendar\",\"center\"]],[23,[\"format\"]]],[[\"locale\"],[[23,[\"calendar\",\"locale\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"calendar\",\"actions\",\"changeCenter\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"ember-power-calendar-nav-control ember-power-calendar-nav-control--next\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],[23,[\"calendar\",\"actions\",\"moveCenter\"]],1,[23,[\"unit\"]],[23,[\"calendar\"]]],null]],[11,\"type\",\"button\"],[9],[0,\"»\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-calendar/templates/components/power-calendar/nav.hbs"
    }
  });

  _exports.default = _default;
});