define("ember-power-calendar/helpers/power-calendar-format-date", ["exports", "ember-power-calendar-utils"], function (_exports, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.powerCalendarFormatDate = powerCalendarFormatDate;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function powerCalendarFormatDate(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 2),
        date = _ref3[0],
        format = _ref3[1];

    var locale = _ref2.locale;
    return (0, _emberPowerCalendarUtils.formatDate)(date, format, locale);
  }

  var _default = Ember.Helper.helper(powerCalendarFormatDate);

  _exports.default = _default;
});