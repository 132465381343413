define("ember-power-calendar/components/power-calendar-multiple", ["exports", "ember-power-calendar/components/power-calendar", "ember-power-calendar-utils"], function (_exports, _powerCalendar, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _powerCalendar.default.extend({
    daysComponent: 'power-calendar-multiple/days',
    _calendarType: 'multiple',
    // CPs
    selected: Ember.computed({
      get: function get() {
        return undefined;
      },
      set: function set(_, v) {
        return Ember.isArray(v) ? v.map(_emberPowerCalendarUtils.normalizeDate) : v;
      }
    }),
    currentCenter: Ember.computed('center', function () {
      var center = this.get('center');

      if (!center) {
        center = (this.get('selected') || [])[0] || this.get('powerCalendarService').getDate();
      }

      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }),
    // Actions
    actions: {
      select: function select(dayOrDays, calendar, e) {
        (false && !(Ember.isArray(dayOrDays) || dayOrDays instanceof Object && dayOrDays.date instanceof Date) && Ember.assert("The select action expects an array of date objects, or a date object. ".concat(_typeof(dayOrDays), " was recieved instead."), Ember.isArray(dayOrDays) || dayOrDays instanceof Object && dayOrDays.date instanceof Date));
        var action = this.get("onSelect");
        var days;

        if (Ember.isArray(dayOrDays)) {
          days = dayOrDays;
        } else if (dayOrDays instanceof Object && dayOrDays.date instanceof Date) {
          days = [dayOrDays];
        }

        if (action) {
          action(this._buildCollection(days), calendar, e);
        }
      }
    },
    // Methods
    _buildCollection: function _buildCollection(days) {
      var selected = this.get("publicAPI.selected") || [];
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        var _loop = function _loop() {
          var day = _step.value;
          var index = selected.findIndex(function (selectedDate) {
            return (0, _emberPowerCalendarUtils.isSame)(day.date, selectedDate, "day");
          });

          if (index === -1) {
            selected = [].concat(_toConsumableArray(selected), [day.date]);
          } else {
            selected = selected.slice(0, index).concat(selected.slice(index + 1));
          }
        };

        for (var _iterator = days[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          _loop();
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return (0, _emberPowerCalendarUtils.normalizeMultipleActionValue)({
        date: selected
      });
    }
  });

  _exports.default = _default;
});