define('ember-gestures/recognizers/pan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    include: [],
    exclude: [],
    options: { direction: typeof Hammer === 'undefined' ? '' : Hammer.DIRECTION_HORIZONTAL },
    recognizer: 'pan'
  };
});