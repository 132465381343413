define('ember-gestures/recognizers/double-tap', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    include: ['tap'],
    exclude: [],
    options: {
      taps: 2
    },
    recognizer: 'tap'
  };
});