define("ember-math-helpers/helpers/sub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sub = sub;
  _exports.default = void 0;

  /**
   * Subtracts two or more numbers
   *
   * ```hbs
   * {{sub a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to subtract
   * @return {number} The difference of all the passed numbers
   */
  function sub(numbers) {
    return numbers.reduce(function (a, b) {
      return Number(a) - Number(b);
    });
  }

  var _default = Ember.Helper.helper(sub);

  _exports.default = _default;
});