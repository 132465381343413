define("ember-power-select/templates/components/power-select/power-select-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YBvHRd3H",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"li\"],[11,\"class\",\"ember-power-select-group\"],[12,\"aria-disabled\",[27,\"ember-power-select-true-string-if-present\",[[23,[\"disabled\"]]],null]],[11,\"role\",\"option\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"ember-power-select-group-name\"],[9],[1,[21,\"groupName\"],false],[10],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/power-select-group.hbs"
    }
  });

  _exports.default = _default;
});