define("ember-math-helpers/helpers/max", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.max = max;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  /**
   * Takes two or more numbers, and finds the max of the set using `Math.max`
   *
   * ```hbs
   * {{max a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to pass to `Math.max`
   * @return {number} The max of the set of numbers
   */
  function max(numbers) {
    return Math.max.apply(Math, _toConsumableArray(numbers));
  }

  var _default = Ember.Helper.helper(max);

  _exports.default = _default;
});