define("ember-parachute/decorators/query-param", ["exports", "ember-parachute/-private/symbols", "ember-parachute/decorators/-private/query-params-for"], function (_exports, _symbols, _queryParamsFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = queryParam;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function createDescriptor(desc, qpDefinition) {
    qpDefinition = qpDefinition || {};

    var descriptor = _objectSpread({}, desc, {
      finisher: function finisher(klass) {
        (0, _queryParamsFor.addQueryParamFor)(klass, desc.key, qpDefinition);
        klass.reopen((0, _queryParamsFor.getQueryParamsFor)(klass).Mixin);
        var proto = klass.proto(); // Remove duplicate queryParams created by the multiple mixins

        if (Array.isArray(proto.queryParams)) {
          var queryParams = Ember.A(_toConsumableArray(proto.queryParams));
          var parachuteQueryParams = queryParams.filterBy(_symbols.PARACHUTE_QPS, true); // Keep the newest one

          parachuteQueryParams.pop(); // Remove the old ones

          queryParams.removeObjects(parachuteQueryParams);
          proto.queryParams = queryParams.toArray();
        }

        return klass;
      }
    });

    if (desc.kind === 'field') {
      if (typeof desc.initializer === 'function') {
        qpDefinition.defaultValue = desc.initializer();
      }

      descriptor.initializer = function initializer() {
        return qpDefinition.defaultValue;
      };
    }

    return descriptor;
  }

  function queryParam(qpDefinition) {
    // Handle `@queryParam` usage
    if ("".concat(qpDefinition) === '[object Descriptor]') {
      return createDescriptor(qpDefinition);
    } // Handle `@queryParam()` usage


    return function (desc) {
      return createDescriptor(desc, qpDefinition);
    };
  }
});