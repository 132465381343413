define("ember-light-table/templates/components/columns/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e1F+MoRT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"column\",\"component\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[23,[\"column\",\"component\"]]],[[\"column\",\"table\",\"tableActions\",\"extra\",\"sortIcons\"],[[23,[\"column\"]],[23,[\"table\"]],[23,[\"tableActions\"]],[23,[\"extra\"]],[23,[\"sortIcons\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"label\"],false],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"sortIcons\",\"iconComponent\"]],[23,[\"sortIconProperty\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"component\",[[23,[\"sortIcons\",\"iconComponent\"]]],[[\"sortIcons\",\"sortIconProperty\"],[[23,[\"sortIcons\"]],[23,[\"sortIconProperty\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"sortIconProperty\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\"],[12,\"class\",[28,[\"lt-sort-icon \",[27,\"get\",[[23,[\"sortIcons\"]],[23,[\"sortIconProperty\"]]],null]]]],[9],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"isResizable\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"lt-column-resizer\",null,[[\"column\",\"table\",\"resizeOnDrag\",\"isResizing\",\"onColumnResized\"],[[23,[\"column\"]],[23,[\"table\"]],[23,[\"resizeOnDrag\"]],[27,\"mut\",[[23,[\"isResizing\"]]],null],[27,\"action\",[[22,0,[]],[23,[\"onColumnResized\"]],[23,[\"column\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/columns/base.hbs"
    }
  });

  _exports.default = _default;
});