define('ember-gestures/utils/string/capitalize-words', ['exports', 'ember-gestures/utils/string/cap-first-letter'], function (exports, _capFirstLetter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (sentence) {
    return sentence.split(' ').map(function (word) {
      return (0, _capFirstLetter.default)(word);
    }).join(' ');
  };
});