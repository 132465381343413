define("ember-modal-dialog/components/liquid-tether-dialog", ["exports", "ember-modal-dialog/components/basic-dialog", "ember-modal-dialog/templates/components/liquid-tether-dialog"], function (_exports, _basicDialog, _liquidTetherDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicDialog.default.extend({
    layout: _liquidTetherDialog.default,
    targetAttachmentClass: Ember.computed('targetAttachment', function () {
      var targetAttachment = this.get('targetAttachment') || '';
      return "ember-modal-dialog-target-attachment-".concat(Ember.String.dasherize(targetAttachment));
    }),
    targetAttachment: null,
    attachment: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.get('attachment')) {
        this.set('attachment', 'middle center');
      }

      if (!this.get('targetAttachment')) {
        this.set('targetAttachment', 'middle center');
      }
    },
    tetherClassPrefix: Ember.computed({
      get: function get() {
        return 'liquid-tether';
      },
      set: function set(key, val) {
        if (val) {
          return val;
        }

        return 'liquid-tether';
      }
    }),
    hasOverlay: true,
    tetherTarget: null // element, css selector, view instance, 'viewport', or 'scroll-handle'
    // offset - passed in
    // targetOffset - passed in
    // targetModifier - passed in

  });

  _exports.default = _default;
});