define("ember-light-table/templates/components/lt-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1drY1V7J",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[4,\"each\",[[23,[\"columns\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[27,\"concat\",[\"light-table/cells/\",[22,1,[\"cellType\"]]],null],[22,1,[]],[23,[\"row\"]]],[[\"table\",\"rawValue\",\"tableActions\",\"extra\"],[[23,[\"table\"]],[27,\"get\",[[23,[\"row\"]],[22,1,[\"valuePath\"]]],null],[23,[\"tableActions\"]],[23,[\"extra\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-light-table/templates/components/lt-row.hbs"
    }
  });

  _exports.default = _default;
});