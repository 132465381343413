define("ember-math-helpers/helpers/min", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.min = min;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  /**
   * Takes two or more numbers, and finds the min of the set using `Math.min`
   *
   * ```hbs
   * {{min a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to pass to `Math.min`
   * @return {number} The min of the set of numbers
   */
  function min(numbers) {
    return Math.min.apply(Math, _toConsumableArray(numbers));
  }

  var _default = Ember.Helper.helper(min);

  _exports.default = _default;
});