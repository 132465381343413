define("ember-parachute/-private/state", ["exports", "ember-parachute/query-params"], function (_exports, _queryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = queryParamsStateFor;

  /**
   * Creates QueryParamsState interface.
   *
   * @param {Ember.NativeArray} queryParamsArray
   * @param {Ember.Controller} controller
   * @returns {object}
   */
  function queryParamsState(queryParamsArray, controller) {
    return queryParamsArray.reduce(function (state, qp) {
      var value = qp.value(controller);
      state[qp.key] = {
        value: value,
        serializedValue: qp.serializedValue(controller),
        as: qp.as,
        defaultValue: qp.defaultValue,
        changed: JSON.stringify(value) !== JSON.stringify(qp.defaultValue)
      };
      return state;
    }, {}, undefined);
  }
  /**
   * Creates new instance of QueryParamsState for a given controller.
   *
   * @export
   * @public
   * @param {Ember.Controller} controller
   * @returns {object}
   */


  function queryParamsStateFor(controller) {
    (false && !(Ember.isPresent(controller)) && Ember.assert('[ember-parachute] Cannot construct query params state object without a controller', Ember.isPresent(controller)));

    var _QueryParams$metaFor = _queryParams.default.metaFor(controller),
        queryParamsArray = _QueryParams$metaFor.queryParamsArray;

    return queryParamsState(queryParamsArray, controller);
  }
});