define('ember-gestures/utils/string/strip-whitespace', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = stripWhiteSpace;
  function stripWhiteSpace(s) {
    return s.replace(/\s/g, '');
  }
});