define('ember-gestures/components/context-element', ['exports', 'ember-gestures/components/gesture-element'], function (exports, _gestureElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _gestureElement.default.extend({

    _getParams: function _getParams(actionName) {
      var actionArguments = this._super(actionName);
      actionArguments.splice(1, 0, this.element);
      return actionArguments;
    }

  });
});