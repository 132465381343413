define("ember-power-calendar/components/power-calendar/nav", ["exports", "ember-power-calendar/templates/components/power-calendar/nav"], function (_exports, _nav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _nav.default,
    tagName: '',
    unit: 'month',
    format: 'MMMM YYYY'
  });

  _exports.default = _default;
});