define("ember-in-viewport/utils/find-elem", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (context) {
    var elem = void 0;
    if (context.nodeType === Node.ELEMENT_NODE || context.nodeType === Node.DOCUMENT_NODE || context instanceof Window) {
      elem = context;
    } else {
      elem = document.querySelector(context);
    }

    return elem;
  };
});