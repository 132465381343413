define("ember-power-calendar/helpers/ember-power-calendar-day-classes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emberPowerCalendarDayClasses = emberPowerCalendarDayClasses;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function emberPowerCalendarDayClasses(_ref) {
    var _ref2 = _slicedToArray(_ref, 4),
        day = _ref2[0],
        calendar = _ref2[1],
        weeks = _ref2[2],
        dayClass = _ref2[3];

    var classes = ['ember-power-calendar-day'];

    if (calendar.actions.select) {
      classes.push('ember-power-calendar-day--interactive');
    }

    classes.push("ember-power-calendar-day--".concat(day.isCurrentMonth ? 'current' : 'other', "-month"));

    if (day.isSelected) {
      classes.push('ember-power-calendar-day--selected');
    }

    if (day.isToday) {
      classes.push('ember-power-calendar-day--today');
    }

    if (day.isFocused) {
      classes.push('ember-power-calendar-day--focused');
    }

    if (day.isRangeStart) {
      classes.push('ember-power-calendar-day--range-start');
    }

    if (day.isRangeEnd) {
      classes.push('ember-power-calendar-day--range-end');
    }

    if (dayClass) {
      if (typeof dayClass === 'string') {
        classes.push(dayClass);
      } else if (typeof dayClass === 'function') {
        var k = dayClass(day, calendar, weeks);

        if (k !== null && k !== undefined) {
          classes.push(k);
        }
      }
    }

    return classes.join(' ');
  }

  var _default = Ember.Helper.helper(emberPowerCalendarDayClasses);

  _exports.default = _default;
});