define("ember-power-calendar/services/power-calendar", ["exports", "ember-power-calendar-utils"], function (_exports, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    date: null,
    // CPs
    locale: Ember.computed(function () {
      return (0, _emberPowerCalendarUtils.getDefaultLocale)();
    }),
    // Methods
    getDate: function getDate() {
      return this.get("date") || new Date();
    }
  });

  _exports.default = _default;
});