define("ember-power-select/templates/components/power-select/before-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CkY7nK1c",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"searchEnabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"ember-power-select-search\"],[9],[0,\"\\n    \"],[7,\"input\"],[11,\"autocomplete\",\"off\"],[11,\"autocorrect\",\"off\"],[11,\"autocapitalize\",\"off\"],[11,\"spellcheck\",\"false\"],[11,\"role\",\"combobox\"],[11,\"class\",\"ember-power-select-search-input\"],[12,\"value\",[23,[\"select\",\"searchText\"]]],[12,\"aria-controls\",[21,\"listboxId\"]],[12,\"placeholder\",[21,\"searchPlaceholder\"]],[12,\"oninput\",[21,\"onInput\"]],[12,\"onfocus\",[21,\"onFocus\"]],[12,\"onblur\",[21,\"onBlur\"]],[12,\"onkeydown\",[27,\"action\",[[22,0,[]],\"onKeydown\"],null]],[11,\"type\",\"search\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/before-options.hbs"
    }
  });

  _exports.default = _default;
});